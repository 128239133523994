<div class="page" size="A4">
  <div class="report-form-full-column">
    <div class="report-form-barcode">
      {{ pii.kitId }}
    </div>
    <ng-container *ngIf="simpleCode">
      <div class="report-form-simple-code">{{ simpleCode }}</div>
    </ng-container>
  </div>
  <div style="clear: both"></div>
  <div class="report-form-full-column">
    <div class="report-form-header">1. PATIENT INFORMATION</div>
    <div class="report-form-region">
      <div class="report-form-inner-left-column">
        <div class="report-form-row">
          <div class="report-form-key">Name of the patient:</div>
          <div class="report-form-value">
            {{ anonymizeName(getFullName(serviceData)) }}
          </div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">Date of birth:</div>
          <div class="report-form-value">
            {{
              moment(serviceData.patientInformation?.dateOfBirth).format(
                MOMENT_DATE_FORMAT
              )
            }}
          </div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">Weight:</div>
          <div class="report-form-value">
            {{ serviceData.patientInformation?.weight }} kg
          </div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">Height:</div>
          <div class="report-form-value">
            {{ serviceData.patientInformation?.height }} cm
          </div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">BMI > 40:</div>
          <div class="report-form-value">
            {{ (patientBMI$ | async) ? 'YES' : 'NO' }}
          </div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>

  <div class="report-form-full-column">
    <div class="report-form-header">2. CURRENT PREGNANCY</div>
    @if (
      serviceData.serviceInformation.nipt.currentPregnancyInformation;
      as pregnancyInformation
    ) {
      <div class="report-form-region">
        <div class="report-form-inner-left-column">
          <div class="report-form-row">
            <div class="report-form-key">Expected birth date:</div>
            <div class="report-form-value">
              {{
                moment(pregnancyInformation?.expectedConfinementDate).format(
                  MOMENT_DATE_FORMAT
                )
              }}
            </div>
          </div>
          <div class="report-form-row">
            <div class="report-form-key">Gestational age at draw:</div>
            <div class="report-form-value">
              {{ pregnancyInformation?.gestationAge?.week }}
              weeks
              {{ pregnancyInformation?.gestationAge?.day }}
              days
            </div>
          </div>
          <div class="report-form-row">
            <div class="report-form-key">No. of fetuses:</div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  pregnancyInformation?.pregnancyType ===
                  PregnancyType.singleton
                "
                >1
              </ng-container>
              <ng-container
                *ngIf="
                  pregnancyInformation?.pregnancyType === PregnancyType.twins
                "
                >2</ng-container
              >
            </div>
          </div>
          <div class="report-form-row">
            <div class="report-form-key">IVF:</div>
            <div class="report-form-value">
              <ng-container
                *ngIf="pregnancyInformation?.inVitro?.isInVitroFertilized"
              >
                Yes,
                {{ pregnancyInformation?.inVitro?.vitroType }}
              </ng-container>
              <ng-container
                *ngIf="!pregnancyInformation?.inVitro?.isInVitroFertilized"
              >
                No
              </ng-container>
            </div>
          </div>
          <div class="report-form-row">
            <div class="report-form-key">Vanishing twin syndrome:</div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  pregnancyInformation?.vanishingTwinSyndrome?.isDetected;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-row">
            <div class="report-form-key">Surrogate mother:</div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  pregnancyInformation?.inVitro?.isSurrogateMother;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-row">
            <div class="report-form-key">
              Patient undertakes heparin therapy:
            </div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  pregnancyInformation?.heparinTherapy?.hasReceived &&
                    !pregnancyInformation?.heparinTherapy?.isRelevant;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-row" style="padding-top: 5px">
            <div class="report-form-key">Date of last ultrasound scan:</div>
            <div class="report-form-value">
              {{
                moment(pregnancyInformation?.ultrasoundDate).format(
                  MOMENT_DATE_FORMAT
                )
              }}
            </div>
          </div>
          <div class="report-form-row">
            <div class="report-form-key">Prior screening test:</div>
            <div class="report-form-value">
              <div class="report-form-value">
                {{
                  pregnancyInformation?.priorScreeningTest
                    ?.priorScreeningTestType
                }}
              </div>
            </div>
          </div>

          @if (
            pregnancyInformation?.priorScreeningTest?.priorScreeningTestType !==
            PriorScreeningTestType.NoTestTaken
          ) {
            <div class="report-form-row">
              <div class="report-form-key">T21 Risk:</div>
              <div class="report-form-value">
                1/{{
                  pregnancyInformation?.priorScreeningTest?.firstTRisks?.t21Risk
                }}
              </div>
            </div>
            <!--$T18RiskVisibility-->
            <div class="report-form-row">
              <div class="report-form-key">T18 Risk:</div>
              <div class="report-form-value">
                1/{{
                  pregnancyInformation?.priorScreeningTest?.firstTRisks?.T18Risk
                }}
              </div>
            </div>
            <!--$T13RiskVisibility-->
            <div class="report-form-row">
              <div class="report-form-key">T13 Risk:</div>
              <div class="report-form-value">
                1/{{
                  pregnancyInformation?.priorScreeningTest?.firstTRisks?.T13Risk
                }}
              </div>
            </div>
          }
        </div>
        <div class="report-form-inner-right-column">
          <div class="report-form-row">
            <div class="report-form-key">&nbsp;</div>
            <div class="report-form-value">&nbsp;</div>
          </div>
          <div class="report-form-row">
            <div class="report-form-key">&nbsp;</div>
            <div class="report-form-value">&nbsp;</div>
          </div>
          <ng-container *ngIf="pregnancyInformation?.chorionicityType">
            <div class="report-form-row">
              <div class="report-form-key">Chorionicity:</div>
              <div class="report-form-value">
                {{ pregnancyInformation?.chorionicityType }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!pregnancyInformation?.chorionicityType">
            <div class="report-form-row">
              <div class="report-form-key">&nbsp;</div>
              <div class="report-form-value">&nbsp;</div>
            </div>
          </ng-container>
          <div class="report-form-row">
            <div class="report-form-key">&nbsp;</div>
            <div class="report-form-value">&nbsp;</div>
          </div>

          <ng-container
            [ngSwitch]="pregnancyInformation?.vanishingTwinSyndrome?.isDetected"
          >
            <ng-container *ngSwitchCase="true">
              <div class="report-form-row">
                <div class="report-form-key-wide">
                  Detected at following gestational age:
                </div>
                <div class="report-form-value">
                  {{
                    pregnancyInformation?.vanishingTwinSyndrome
                      ?.detectedGestationAge?.week
                  }}
                  weeks
                  {{
                    pregnancyInformation?.vanishingTwinSyndrome
                      ?.detectedGestationAge?.day
                  }}
                  days
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="report-form-row">
                <div class="report-form-key">&nbsp;</div>
                <div class="report-form-value">&nbsp;</div>
              </div>
            </ng-container>
          </ng-container>

          <div class="report-form-row">
            <div class="report-form-key">&nbsp;</div>
            <div class="report-form-value">&nbsp;</div>
          </div>

          <div class="report-form-row">
            <div class="report-form-key">&nbsp;</div>
            <div class="report-form-value">&nbsp;</div>
          </div>

          <div class="report-form-row">
            <div class="report-form-key">&nbsp;</div>
            <div class="report-form-value">&nbsp;</div>
          </div>

          <div class="report-form-row">
            <div class="report-form-key">&nbsp;</div>
            <div class="report-form-value">&nbsp;</div>
          </div>

          <ng-container
            *ngIf="
              pregnancyInformation?.pregnancyType === PregnancyType.twins &&
              pregnancyInformation?.priorScreeningTest
                ?.priorScreeningTestType !== PriorScreeningTestType.NoTestTaken
            "
          >
            <!--$T212RiskVisibility-->
            <div class="report-form-row">
              <div class="report-form-key">T21 Risk:</div>
              <div class="report-form-value">
                2/{{
                  pregnancyInformation?.priorScreeningTest?.secondTRisks
                    ?.T21Risk
                }}
              </div>
            </div>
            <!--$T182RiskVisibility-->
            <div class="report-form-row">
              <div class="report-form-key">T18 Risk:</div>
              <div class="report-form-value">
                2/{{
                  pregnancyInformation?.priorScreeningTest?.secondTRisks
                    ?.T18Risk
                }}
              </div>
            </div>
            <!--$T132RiskVisibility-->
            <div class="report-form-row">
              <div class="report-form-key">T13 Risk:</div>
              <div class="report-form-value">
                2/{{
                  pregnancyInformation?.priorScreeningTest?.secondTRisks
                    ?.T13Risk
                }}
              </div>
            </div>
          </ng-container>
        </div>
        <div style="clear: both"></div>
      </div>
    }
  </div>

  @if (serviceData.serviceInformation.nipt.patientHistory; as patientHistory) {
    <div class="report-form-full-column">
      <div class="report-form-header">3. PREGNANCY HISTORY</div>
      <div class="report-form-region">
        <div class="report-form-row">
          <div class="report-form-inner-left-column">
            <div class="report-form-key">Number of pregnancies:</div>
            <div class="report-form-value">
              {{ patientHistory?.numberOfPregnancies }}
            </div>
          </div>
          <div class="report-form-inner-right-column">
            <div class="report-form-key">Number of births:</div>
            <div class="report-form-value">
              {{ patientHistory?.numberOfBirths }}
            </div>
          </div>
        </div>

        <div class="report-form-row">
          <div class="report-form-full-column-inner">
            <div class="report-form-key">
              Previous pregnancies affected by genetic condition:
            </div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  patientHistory?.pregnancyDiseaseHistory?.hasHistory;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-row">
            <ng-container
              *ngIf="patientHistory?.pregnancyDiseaseHistory?.hasHistory"
            >
              <div class="report-form-inner-col-8">
                <div class="report-form-row text-start">
                  {{
                    patientHistory?.pregnancyDiseaseHistory?.diseases[0]
                      .conditionDescription
                  }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="report-form-row">
          <div class="report-form-full-column-inner">
            <div class="report-form-key">
              Family history of genetic disease:
            </div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  patientHistory?.familyDiseaseHistory.hasHistory;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-row">
            <ng-container
              *ngIf="patientHistory?.familyDiseaseHistory.hasHistory"
            >
              <div class="report-form-inner-col-8">
                <div class="report-form-row text-start">
                  {{
                    patientHistory?.familyDiseaseHistory?.diseases[0]
                      .conditionDescription
                  }}<br />
                  Relation to patient:
                  {{
                    patientHistory?.familyDiseaseHistory?.diseases[0]
                      .relationToPatient
                  }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="report-form-row">
          <div class="report-form-full-column-inner">
            <div class="report-form-key">
              Is the patient or her partner carrier of a genetic condition:
            </div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  patientHistory?.carrierOfGeneticDiseases.hasHistory;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-row">
            <ng-container
              *ngIf="patientHistory?.carrierOfGeneticDiseases.hasHistory"
            >
              <div class="report-form-inner-col-8">
                <div class="report-form-row text-start">
                  {{
                    patientHistory?.carrierOfGeneticDiseases?.diseases[0]
                      .conditionDescription
                  }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="report-form-row">
          <!-- <div class="report-form-inner-left-column"> -->
          <div class="report-form-key">History of cancer</div>
          <div class="report-form-value">
            <ng-container *ngIf="!patientHistory?.cancerHistory?.hasHistory">
              No
            </ng-container>
            <ng-container *ngIf="patientHistory?.cancerHistory?.hasHistory">
              Yes, {{ patientHistory?.cancerHistory?.status }}
            </ng-container>
          </div>
        </div>
        <!-- </div> -->

        <div class="report-form-row">
          <div class="report-form-inner-left-column">
            <div class="report-form-key">
              Patient received human serum albumin therapy?
            </div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  patientHistory?.albuminTherapy?.hasReceived;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-inner-right-column">
            <ng-container
              [ngSwitch]="patientHistory?.albuminTherapy?.hasReceived"
            >
              <ng-container *ngSwitchCase="true">
                <!-- <div class="report-form-row"> -->
                <div class="report-form-key">
                  Has it been more than 4 weeks since the last dose?
                </div>
                <ng-container
                  *ngIf="
                    !patientHistory?.albuminTherapy?.isRelevant;
                    then yesChoice;
                    else noChoice
                  "
                >
                </ng-container>
                <!-- </div> -->
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class="report-form-row">
                  <div class="report-form-key">&nbsp;</div>
                  <div class="report-form-value">&nbsp;</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="report-form-row">
          <div class="report-form-inner-left-column">
            <div class="report-form-key">
              Patient received cellular immunotherapy with exogenous DNA?
            </div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  patientHistory?.cellularImmunotherapy?.hasReceived;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-inner-right-column">
            <ng-container
              [ngSwitch]="patientHistory?.cellularImmunotherapy?.hasReceived"
            >
              <ng-container *ngSwitchCase="true">
                <!-- <div class="report-form-row"> -->
                <div class="report-form-key">
                  Has it been more than 4 weeks since the last dose?
                </div>
                <ng-container
                  *ngIf="
                    !patientHistory?.cellularImmunotherapy?.isRelevant;
                    then yesChoice;
                    else noChoice
                  "
                >
                </ng-container>
                <!-- </div> -->
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class="report-form-row">
                  <div class="report-form-key">&nbsp;</div>
                  <div class="report-form-value">&nbsp;</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <!-- <div class="report-form-full-column-inner"> -->
        <div class="report-form-row">
          <div class="report-form-key">Patient received stem cell therapy?</div>
          <div class="report-form-value">
            <ng-container
              *ngIf="
                patientHistory?.stemCellTherapy?.hasReceived;
                then yesChoice;
                else noChoice
              "
            >
            </ng-container>
          </div>
        </div>
        <!-- </div> -->

        <!-- <div class="report-form-full-column-inner"> -->
        <div class="report-form-row">
          <div class="report-form-key">Patient had transplant surgery?</div>
          <div class="report-form-value">
            <ng-container
              *ngIf="
                patientHistory?.transplantSurgery?.hasReceived;
                then yesChoice;
                else noChoice
              "
            >
            </ng-container>
          </div>
        </div>
        <!-- </div> -->

        <div class="report-form-row">
          <div class="report-form-inner-left-column">
            <div class="report-form-key">
              Patient received blood transfusion?
            </div>
            <div class="report-form-value">
              <ng-container
                *ngIf="
                  patientHistory?.bloodTransfusion?.hasReceived;
                  then yesChoice;
                  else noChoice
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="report-form-inner-right-column">
            <ng-container
              [ngSwitch]="patientHistory?.bloodTransfusion?.hasReceived"
            >
              <ng-container *ngSwitchCase="true">
                <div class="report-form-row">
                  <div class="report-form-key">
                    Has more than one year passed since received transfusion?
                  </div>
                  <div class="report-form-value">
                    &nbsp;
                    <ng-container
                      *ngIf="
                        !patientHistory?.bloodTransfusion?.isRelevant;
                        then yesChoice;
                        else noChoice
                      "
                    >
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class="report-form-row">
                  <div class="report-form-key">&nbsp;</div>
                  <div class="report-form-value">&nbsp;</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
    </div>
  }

  <div class="report-form-full-column">
    <div class="report-form-header">4. SAMPLING</div>
    <div class="report-form-region">
      <div class="report-form-inner-left-column">
        <div class="report-form-row">
          <div class="report-form-key">Name of the doctor:</div>
          <div class="report-form-value">{{ pii?.sample?.doctor?.name }}</div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">Date of blood draw:</div>
          <div class="report-form-value">
            {{
              moment(pii?.sample?.dateOfVenipuncture).format(
                MOMENT_DATE_TIME_FORMAT_SLASH
              )
            }}
          </div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">Service type:</div>
          <div class="report-form-value">
            {{ pii?.product?.name }}
          </div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">Repeated sample:</div>
          <div class="report-form-value">
            <ng-container
              *ngIf="previousSampleKitId; then yesChoice; else noChoice"
            >
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="previousSampleKitId">
          <div class="report-form-row">
            <div class="report-form-key">Repetition of:</div>
            <div class="report-form-value">
              {{ previousSampleKitId }}
            </div>
          </div>
        </ng-container>
        <div class="report-form-row">
          <div class="report-form-key">DHL waybill number:</div>
          <div class="report-form-value">
            {{ pii?.sample?.shippingInformation?.waybill }}
          </div>
        </div>
      </div>
      <div class="report-form-inner-right-column">
        <div class="report-form-row">
          <div class="report-form-key">&nbsp;</div>
          <div class="report-form-value">&nbsp;</div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">&nbsp;</div>
          <div class="report-form-value">&nbsp;</div>
        </div>
        <div class="report-form-row">
          <ng-container
            [ngSwitch]="
              pii?.product?.internalName.toUpperCase().includes('PLUS')
            "
          >
            <ng-container *ngSwitchCase="true">
              <div class="report-form-row">
                <div class="report-form-key">Incidental findings:</div>
                <div class="report-form-value">
                  <ng-container
                    *ngIf="
                      serviceData?.serviceInformation?.nipt?.incidentalFindings;
                      then yesChoice;
                      else noChoice
                    "
                  >
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="report-form-row">
                <div class="report-form-key">&nbsp;</div>
                <div class="report-form-value">&nbsp;</div>
              </div>
            </ng-container>
          </ng-container>
          <!-- incidental findings-->
        </div>
        <div class="report-form-row">
          <div class="report-form-key">&nbsp;</div>
          <div class="report-form-value">&nbsp;</div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">&nbsp;</div>
          <div class="report-form-value">&nbsp;</div>
        </div>
        <div class="report-form-row">
          <div class="report-form-key">&nbsp;</div>
          <div class="report-form-value">&nbsp;</div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>

  <div class="report-form-full-column">
    <div class="report-form-header">5. GENDER IDENTIFICATION</div>
    <div class="report-form-region">
      <div class="report-form-inner-left-column">
        <div class="report-form-row">
          <div class="report-form-key">Gender identification:</div>
          <div class="report-form-value">
            <ng-container *ngTemplateOutlet="yesChoice"></ng-container>
          </div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>

  <div
    class="report-form-full-column"
    *ngIf="serviceData?.serviceInformation?.nipt?.comments"
  >
    <div class="report-form-header">6. COMMENTS</div>
    <div class="report-form-region">
      <div class="report-form-inner-left-column">
        <div class="report-form-row">
          <div>
            {{ serviceData?.serviceInformation?.nipt?.comments }}
          </div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</div>

<ng-template #yesChoice> Yes </ng-template>
<ng-template #noChoice> No </ng-template>

<ng-template #noRecords>
  <div class="report-form-inner-col-12">
    <div class="report-form-row text-center">Empty</div>
  </div>
</ng-template>
