import { Directive, TemplateRef } from '@angular/core';
import { PdfPreviewTemplateStateService } from '@app/modules/pdf-request-form/services/pdf-preview-template-state.service';

@Directive({
  selector: '[appPdfPreview]',
  standalone: true,
})
export class PdfPreviewDirective {
  constructor(
    pdfPreviewTemplate: TemplateRef<any>,
    state: PdfPreviewTemplateStateService
  ) {
    state.pdfTemplate = pdfPreviewTemplate;
  }
}
